.ae-footer {
   background-color: $footer-bg-color;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 40px;
    font-size: .75rem;
    font-family: $font-family-roboto;

    .ae-footer-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        max-width: 990px;
        margin-left: auto;
        margin-right: auto;
    }


    .ae-footer-content {
        flex-grow: 1;
        flex-shrink: 0;

        .nav {
            margin-bottom: 24px;
        }

        .nav-item {
            &:not(:last-child) {
                margin-right: 1rem;
            }
        }

        .nav-link {
            font-size: .75rem;
            font-weight: 400;
            color: $footer-text-color !important;
            text-decoration: underline !important;
            padding: 0;

            &:hover {
                text-decoration: none !important;
            }

        }
    }

    .ae-copyright {
        font-size: .75rem;
        color: $footer-text-color;
        font-weight: 400;
        margin-bottom: 40px;
    }

    .ae-footer-socials-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h6 {
            color: $footer-text-color; 
            font-size: .875rem;
            font-weight: 400;
            margin-bottom: .5rem;
            text-transform: uppercase;
        }

        ul {
            margin-bottom: 30px;
        }

        .nav-item {
            display: block;

            &:not(:last-child) {
                margin-right:17px;
            }

            .nav-link {
                padding: 0 !important;
            }

            img {
                width: 32px;
                height: 32px;
            }
        }
    }
}

.ae-logo-giroe {
    display: block; 

    img {
        height: 84px; 
        width: 80px;
    }
    
}

.ae-nhag-logo-footer {
    width: 159px;
    height: 85px;
    margin-right: 40px;
    margin-left: 0px;
}


.ae-footer-logos {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 990px;
    margin-left: auto;
    margin-right: auto;
}



@include media-breakpoint-down(md) {
    .ae-footer {

        .container {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
        }

        .ae-footer-container {
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }


        .ae-footer-content {
            max-width: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            order: 2; 
        }

        .ae-footer-socials-container {
            order: 1;

            h6 {
                text-align: center !important;
            }
        }

        .ae-copyright {
            margin-bottom: 0;
            order: 4;
            text-align: center;
        }

        .nav {
            order: 3;
            margin-bottom: 30px;
            justify-content: space-around;

            .nav-item {
                margin-bottom: .5rem;
            }
        }
    }

    .ae-logo-mynh {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }

    .ae-nhag-logo-footer {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }

    .ae-cnhcapital-logo-footer {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }

    .ae-footer-logos {
        flex-direction: column;
        justify-content: space-between;
        align-items: center; 
    }


}