#landing-form {
    padding: 0;
    color: $contacts-intro-color !important;
    transition: opacity .3s linear;

    &.ae-form-hidden {
        opacity: 0;
    }

    .row {
        display: block;
        flex-wrap: unset;
        margin-right: 0;
        margin-left: 0;

        .col-12 {
            display: block;
            padding: 0;
            margin: 0;
            flex: none;

            &.text-right {
                text-align: center !important;
            }
        }
    }

    label:not(.custom-control-label) {
        display: none !important;
    }

    label[for='stage'] {
        display: block !important;
        position: absolute;
        top: 8px;
        left: 12px;
        right: 30px;
        font-weight: 400;
        line-height: 1.5;
        font-size: .875rem;
        color: $input-color;
        z-index: 10;
        pointer-events: none !important;
        touch-action: none !important;
        font-family: $font-family-lato;

        &.ae-hidden {
            display: none !important;
        }
    }

    .btn-toolbar {
        display: block !important;
        float: none !important;
        flex-wrap: unset !important;
        flex-direction: unset !important;
    }

    .btn-group {
        display: block !important;
    }

    .btn-primary {
        flex: none !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        float: none !important;
        text-transform: uppercase;
        color: $submit-color;
        min-width: 160px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 0 !important;
    }
}

body.nl {
    #landing-form label[for='stage'] {
        right:29px;
    }
}

select[multiple='multiple'] {
    display: block !important;
    visibility: hidden !important;
    height: 0 !important;
    overflow: hidden !important;
    padding: 0 !important;
}

.form-control {
    height: 33px;
    background-clip: unset;
    border: 1px solid transparent;
    border-radius: 0;
    font-size: .875rem;

    &.mt-1 {
        margin-top: 0 !important;
    }
}


.form-group {
    position: relative;
    margin-bottom: .5rem;


    input,
    select {
        &.input-validation-error {
            border-color: $error-color !important;


            &+div {
                border-color: $error-color !important;
            }

            &+span,
            &+div+span {
                color: $error-color !important;
                font-size: .75rem;
                position: absolute;
                right: .5rem;
                bottom: .1rem;
                font-weight: 400;
            }
        }

    }

    input {
        &[type='radio'] {
            &.input-validation-error {
                &+span {

                    right: -15.5rem;
                    top: -1.7rem;

                    &+label:before {
                        border-color: $error-color;
                    }
                }
            }

            &.input-validation-error {
                &+label:before {
                    border-color: $error-color;
                }
            }
        }

        &[type='checkbox'] {
            &.input-validation-error {
                &+label:before {
                    border-color: $error-color;
                }
            }

            &+span {
                &+label:before {
                    border-color: $error-color;
                }
            }
        }
    }
}

#stage_chosen {
    padding-right: 30px;

    &:after {
        content: "";
        background: #fff url(../images/icons/caret-down-fill.svg) right 0.75rem center/14px 14px no-repeat;
        position: absolute;
        width: 24px;
        height: 16px;
        top: 9px;
        right: 0;
    }
}


//GDPR
.ae-lid-form-cnhgdpr {
    font-size: .625rem;
    color: $form-gdpr-color;
    margin-top: 24px;
    margin-bottom: 20px;
    font-family: $font-family-lato;

    a {
        color: $form-gdpr-color !important;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .field-validation-error[data-valmsg-for='gdpr_1'],
    .field-validation-error[data-valmsg-for='gdpr_2'] {
        position: absolute;
        width: auto !important;
        top: auto !important;
        left: 0 !important;
        bottom: -1rem !important;
        color: $error-color !important;
    }


    .custom-checkbox {
        .field-validation-error {
            position: absolute;
            bottom: -.9rem !important;
            left: 0 !important;
            color: $error-color !important;
        }
    }

}

.gdpr-policy-label,
.gdpr-scrollable {
    color: $form-gdpr-color;
    font-size: .625rem !important;
}

.ae-lid-form-cnhgdpr {

    .gdpr-scrollable {
        margin-bottom: .25rem;
    }

    .custom-control-label {
        color: $form-gdpr-color;
        font-size: .625rem !important;
        padding-top: 5px;

        a {
            color: $form-gdpr-color !important;
        }
    }

    .form-group {
        margin-bottom: 1.75rem;
    }
}

.ae-fields-mandatory {
    font-size: .625rem;
    margin-bottom: 2rem;
    color: $form-gdpr-color;
    padding-left: 0px;
    padding-right: 0;
    text-align: right;
    font-family: $font-family-lato;
}

.ae-form-internal-message {
    font-size: .625rem;
    color: $form-gdpr-color;
    margin-top: 40px;
    margin-bottom: 24px;
    font-family: $font-family-lato;
}


#request-error {
    color: $error-color !important;
    font-size: .75rem;
    font-weight: 900;
    position: absolute;
    top: .4rem;
    left: 6rem;

}

.custom-control-label::before {
    background-color: #FFF;
}


.ae-mynewholland {
    label.custom-control-label {
        color: #000 !important;
        font-size: .625rem !important;
        margin-top: 1rem;
        padding-top: 5px;

        strong {
            font-weight: 700 !important;
        }
    }
}


.ae-form-container {
    position: relative;
}

#waiting-form {

    position: absolute;
    top: 0rem;
    left: 47%;
    z-index: 10;

    &.text-white {
        color: $secondary !important;
    }
}


.ae-lid-submitting {
    #waiting-form {
        display: inline-block !important;
    }
}




@media(min-width:992px) {
    body.de,
    body.es,
    body.it,
    body.nl,
    body.en,
    body.pl,
    body.pt {
        #landing-form label[for='stage']{ 
            font-size: .75rem;
            top: 10px;
        }

        .form-control { 
            font-size: .75rem;
        }

        .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
            top: 1px;
        }
    }

    body.fr {
        #landing-form label[for='stage']{ 
            font-size: .70rem;
            top: 10px;
        }

        .form-control { 
            font-size: .70rem;
        }

        .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
            top: 1px;
        }
    }
}


@media (max-width:map-get($grid-breakpoints, "md")) {


    .form-control {
        width: 100%;
        font-size: 1rem;
        height: auto;
    }

    label[for='stage'] {
        font-size: 1rem;
    }


    #landing-form {
        padding-left: 0;
        padding-right: 0;
    }
}


@media (max-width:576px) {

    label[for='stage'] {
        font-size: 1rem;
    }

    .gdpr-policy-label,
    .gdpr-scrollable {
        font-size: .875rem !important;
    }

    .ae-lid-form-cnhgdpr {
        margin-left: 0;
        margin-right: 0;
        font-size: .875rem;

        .custom-control-label {
            font-size: .875rem !important;
            padding-top: 0px;
        }
    }

    .ae-fields-mandatory {
        margin-left: 0px;
        margin-right: 0px;
        font-size: .875rem !important;
    }

    input {
        &[type='radio'] {
            &.input-validation-error {
                &+span {
                    width: 120px;
                }
            }
        }
    }

    .form-group {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        margin-bottom: 12px;

        .form-control {
            width: 100%;
        }
    }


    .ae-mynewholland {
        label.custom-control-label {
            font-size: .875rem !important;
            padding-top: 0px;
        }
    }

    .ae-form-internal-message {
        font-size: .875rem;
        margin-top: 24px;
    }

}