.ae-img-spinner {
    position: relative ;
    display: block;

    &:after {
        content: "";
        display: inline-block;
        width: 2rem;
        height: 2rem;
        vertical-align: text-bottom;
        border: .25em solid  $primary;
        border-right-color: transparent;
        border-radius: 50%;
        animation: spinner-border .75s linear infinite;
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 1;
        transition: opacity .2s ease;
        z-index: 10;
    }

    &.ae-loaded {
        &:after {
            opacity: 0;
            z-index: -20;
        }
    }

}

.lazyload,
.lazyloading {
	opacity: 0;
}
.lazyloaded {
	opacity: 1;
	transition: opacity 350ms;
}