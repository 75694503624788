header,
section,
footer {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    flex-shrink: 0;
}

.ae-mobile-header,
.ae-btn-cta-container {
    display: none !important;
}

.ae-main-container {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-size: auto $ae-bg-size;
    background-position: center top;
    min-height: $ae-bg-size;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    opacity: 1 !important;
}

.ae-form-container {
    border-radius: 31px;
    background: $form-bg-color;
    padding-top: 45px;
    padding-bottom: 60px;
    padding-right: 32px;
    padding-left: 32px;
    max-width: 546px;
    width: 100%;
    opacity: 1 !important;
    margin-left: auto;
    margin-right: 0;
    flex-shrink: 0;
    min-height: 950px;
}

main {
    width: 100%;
    max-width: 1360px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}

footer {
    height: 250px;
}

.ae-nhg-logo-header {
    width: 150px;
    padding-top: 40px;
    padding-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 111px;
}

.ae-main-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    flex-grow: 1;
    margin-bottom: 1.5rem;
}

.ae-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 870px;
    padding-bottom: 30px;

    .ae-btn-cta-container {
        text-align: center;
    }
}

h1 {
    font-size: 2.688rem;
    letter-spacing: -1.29px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 2.813rem;
    width: 100%;
    margin-top: 56px;


    strong {
        width: 100%;
        font-size: 3rem;
        letter-spacing: -1.44px;
        font-weight: 600;
        color: #007EC6;
        text-transform: uppercase;
        text-align: center;
        display: block;
        margin-top: 1.125rem;
    }

    span {
        display: block;
        width: 100%;
        text-transform: uppercase;
        text-align: center;

        &.ae-big {
            font-size: 5.25rem;
            letter-spacing: -2.52px;
            line-height: 1.05;
            margin-top: 1.875rem;
            margin-bottom: 1.875rem;
        }

        &.ae-medium {
            font-size: 3.125rem;
            letter-spacing: -1.5px;
            line-height: 1.04;
        }
    }
}

// body.it {
//     h1 {
//         letter-spacing: 1.71px;
//     }
// }

h2 {
    font-size: 2.563rem;
    text-align: center;
    letter-spacing: 0px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    margin-bottom: 24px;
}

.ae-form-container {

    h2,
    h3 {
        text-align: center;
        text-transform: uppercase;
        color: $contacts-title-color;
        font-weight: 700;
        font-size: 1.875rem;
        margin-bottom: 1rem;
    }

    p {
        color: $contacts-intro-color;
        font-size: 1.25rem;
        line-height: 1.563rem;
        text-align: center;
        font-weight: 700;
        letter-spacing: -0.2px;
        margin-bottom: 33px;
    }
}

.btn {
    font-weight: 700;
    box-shadow: 0px 3px 6px #00000029;
}

.bootbox-body {
    h5,
    div {
        color: #000 !important;
    }
}

.bootbox-accept {
    background-color: $secondary !important;
    color: #FFF !important;
}

.ae-promo-container {
    width: 100%;
    margin-bottom: 8px;
    background-color: transparent;

    h2 {
        color: $secondary;
        font-size: 2.625rem;
        text-align: center;
        margin-bottom: 4.688rem;
    }

    .ae-promo-box {
        border-radius: 44px;
        padding: 37px 90px;
        text-align: left;
        box-shadow: 0px 7px 20px #00000019;
        background-color: #FFF;

        h3 {
            font-size: 1.875rem;
            line-height: 2.5rem;
            font-weight: 900;
            margin-bottom: .875rem;

            strong {
                color: $secondary;
            }
        }

        p {
            font-size: 1.125rem;
            line-height: 2.188rem;
            margin-bottom: 2.625rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .ae-btn-cta-container {
            margin-bottom: 2rem;
        }

        small {
            font-size: .875rem;
            text-align: left !important;
            font-style: italic;
            display: block;
        }
    }
}

.ae-info-logo {
    padding-left: 40px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 230px;
    width: auto;
}

@media (max-width:1199.98px) {
    h1 {
        font-size: 2.5rem;
    }
}

@media (max-width: 1099.98px) {
    .ae-form-container {
        max-width: 520px;
    }

    h1 {
        font-size: 2rem;
        line-height: 1.04;
    }
}

@media (max-width: 991px) {
    .ae-main-container {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left -100px top;
        height: auto;
    }

    main {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
    }



    .ae-main-content {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .ae-info-container {
        height: auto;
        padding-bottom: 40px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .ae-form-container {
        margin-left: 0;
        max-width: 710px;
        width: 100%;
        min-height: auto;
    }

    h1 {
        font-size: 2rem;
    }

    .ae-promo-container {
        margin-bottom: 0;

        .ae-promo-box {
            padding: 20px;

            h3 {
                font-size: 1.25rem;
                line-height: 1.5;
            }

            p {
                text-align: left !important;
            }
        }
    }
}

@media (min-width:769.98px) and (max-width:991.98px) {

    h1 {
        margin-top: 24px;
    }

    .ae-info-container {
        height: 68vh;
        margin-bottom: 20px;
    }

    .ae-promo-container {
        padding-left: 15px;
        padding-right: 15px;
    }
}


@media (min-width:576.98px) and (max-width:769.98px) {

    h1 {
        margin-top: 24px;
    }

    .ae-info-container {
        height: 80vh;
        margin-bottom: 20px;
    }

    .ae-promo-container {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-width: 576px) {

    h1 {
        font-size: 1.75rem;
        line-height: 1.2;
        margin-top: 24px;
        margin-bottom: 32px;
    }

    .ae-info-container,
    .ae-logo-desktop {
        display: none !important;
    }

    main {
        flex-direction: column-reverse;
    }

    .ae-main-container {
        background-repeat: no-repeat;
        background-size: 100vw auto;
        background-position: center top;
        height: auto;
        min-height: auto;
    }

    .ae-main-content {
        margin-bottom: 0;
    }

    .ae-form-container {
        border-radius: 0;
        padding-left: 15px;
        padding-right: 15px;
    }

    .ae-promo-container {
        width: 100%;
        padding: 15px;
        margin-bottom: 24px;
        margin-top: 600px;

        .ae-promo-box {
            padding: 30px;
            border-radius: 30px;

            p {
                font-size: 1rem;
                line-height: 1.6;
                margin-bottom: 1.5rem;
            }
        }
    }

    .ae-footer-socials-container {
        margin-top: 42px;
    }

    .ae-mobile-header {
        display: block !important;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;

        .ae-nhg-logo-header {
            padding-top: 30px;
            height: 101px;
        }
    }

    .ae-btn-cta-container {
        display: block !important;
        text-align: center;
        margin-bottom: 30px;

        .ae-btn-cta {
            box-shadow: 0px 3px 6px #00000029;
            padding: 0.688rem 1.125rem;
            font-size: 1.125rem;
        }
    }
}

@media(max-width: 540px) {
    .ae-promo-container {
        margin-top: 600px;
    }

    body.nl {
        .ae-promo-container {
            margin-top: 650px;
        }
    }
}

@media(max-width: 430px) {
    .ae-promo-container {
        margin-top: 400px;
    }

    body.nl {
        .ae-promo-container {
            margin-top: 450px;
        }
    }
}

@media(max-width: 400px) {
    .ae-promo-container {
        margin-top: 375px;
    }
    
    body.nl {
        .ae-promo-container {
            margin-top: 415px;
        }
    }
}

@media(max-width: 375px) {
    .ae-promo-container {
        margin-top: 350px;
    }

    body.nl {
        .ae-promo-container {
            margin-top: 390px;
        } 
    }
}

@media(max-width: 360px) {
    .ae-promo-container {
        margin-top: 325px;
    }

    body.nl {
        .ae-promo-container {
            margin-top: 365px;
        }
    }
}

@media(max-width: 300px) {
    .ae-promo-container {
        margin-top: 200px;
    }

    body.nl {
        .ae-promo-container {
            margin-top: 255px;
        }
    }

    h1 {
        font-size: 1.125rem;
    }
}