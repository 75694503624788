
$font-family-roboto: 'Roboto', sans-serif;
$font-family-roboto-condensed: 'Roboto Condensed', sans-serif;
$font-family-lato: 'Lato', sans-serif;


$font-family-base: $font-family-roboto-condensed;
$enable-responsive-font-sizes: false; 

$input-font-family: $font-family-lato;
$btn-font-family: $font-family-lato;

$body-bg: #FFF;
$body-color: #4D4D4F;
$primary: #FFF;
$secondary: #E73368;
$link-color: #E73368;
$link-decoration: underline;
$link-hover-color: #E73368;
$link-hover-decoration: none;
$submit-color: #E73368;

$error-color: #fd7e14; 
$footer-text-color: #4D4D4F;
$footer-bg-color: transparent;
$form-bg-color:  transparent linear-gradient(153deg, #E73368 0%, #3F2868 100%) 0% 0% no-repeat padding-box;
$form-gdpr-color: #FFF; 

$contacts-title-color:#FFF; 
$contacts-intro-color: #FFF; 


$input-color: #000;
$input-placeholder-color: #000;

$custom-checkbox-indicator-border-radius: 0; 

$btn-border-radius: 6px;

$ae-bg-size: auto; 

$custom-select-indicator: url(../images/icons/caret-down-fill.svg);
$custom-select-bg-size: 14px 14px;

$custom-checkbox-indicator-icon-checked:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#4D4D4F' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>");
$custom-radio-indicator-icon-checked:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#4D4D4F'/></svg>");

// Bootstrap and its default variables
@import "vendor/bootstrap-4.6/bootstrap";
// Chosen
@import "vendor/chosen/chosen.scss";

@import "reset.scss";
@import "lazyloading.scss";
@import "layout.scss"; 
@import "contacts.scss";
@import "footer.scss";   
